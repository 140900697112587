import React from 'react';

const Users2x: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91226 26.2455C4.35004 24.8077 6.30009 24 8.33341 24H21.6667C23.7001 24 25.6501 24.8077 27.0879 26.2455C28.5257 27.6833 29.3334 29.6333 29.3334 31.6667V35C29.3334 35.5523 28.8857 36 28.3334 36C27.7811 36 27.3334 35.5523 27.3334 35V31.6667C27.3334 30.1638 26.7364 28.7224 25.6737 27.6597C24.611 26.597 23.1696 26 21.6667 26H8.33341C6.83052 26 5.38918 26.597 4.32648 27.6597C3.26377 28.7224 2.66675 30.1638 2.66675 31.6667V35C2.66675 35.5523 2.21903 36 1.66675 36C1.11446 36 0.666748 35.5523 0.666748 35V31.6667C0.666748 29.6333 1.47448 27.6833 2.91226 26.2455Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 6C11.8703 6 9.33325 8.53705 9.33325 11.6667C9.33325 14.7963 11.8703 17.3333 14.9999 17.3333C18.1295 17.3333 20.6666 14.7963 20.6666 11.6667C20.6666 8.53705 18.1295 6 14.9999 6ZM7.33325 11.6667C7.33325 7.43248 10.7657 4 14.9999 4C19.2341 4 22.6666 7.43248 22.6666 11.6667C22.6666 15.9009 19.2341 19.3333 14.9999 19.3333C10.7657 19.3333 7.33325 15.9009 7.33325 11.6667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.365 24.9667C32.5031 24.4319 33.0485 24.1104 33.5832 24.2484C35.228 24.6731 36.6851 25.632 37.7259 26.9745C38.7666 28.3171 39.332 29.9672 39.3332 31.6659L39.3333 35C39.3333 35.5523 38.8855 36 38.3333 36C37.781 36 37.3333 35.5523 37.3333 35V31.6674C37.3323 30.4119 36.9144 29.1922 36.1452 28.1999C35.3759 27.2076 34.2989 26.4988 33.0833 26.1849C32.5485 26.0468 32.2269 25.5014 32.365 24.9667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.698 4.96863C25.835 4.43361 26.3798 4.11093 26.9148 4.24792C28.5639 4.67017 30.0256 5.62927 31.0694 6.97402C32.1132 8.31877 32.6798 9.97268 32.6798 11.675C32.6798 13.3773 32.1132 15.0312 31.0694 16.376C30.0256 17.7207 28.5639 18.6798 26.9148 19.1021C26.3798 19.2391 25.835 18.9164 25.698 18.3814C25.561 17.8463 25.8837 17.3016 26.4187 17.1646C27.6376 16.8525 28.718 16.1436 29.4895 15.1496C30.261 14.1557 30.6798 12.9332 30.6798 11.675C30.6798 10.4168 30.261 9.19431 29.4895 8.20036C28.718 7.20641 27.6376 6.49751 26.4187 6.18542C25.8837 6.04843 25.561 5.50366 25.698 4.96863Z"
      />
    </svg>
  );
};

export default Users2x;
